import FeatureGroup from "../FeatureGroup";
import * as S from './styles'
import {FootNote} from "./styles";

const groups = [
  {
    title: "Общие технические характеристики",
    features: [
      {
        description: "напряжение питания",
        value: "220В, 50 Гц",
      },
      {
        description: "потребляемая мощность",
        value: "не более 25 Вт",
      },
      {
        description: "рабочая температура окружающего воздуха",
        value: "от +5 до +40 °С",
      },
      {
        description: "габаритные размеры",
        value: "300х250х120 мм",
      },
      {
        description: "масса изделия",
        value: "не более 5 кг",
      },
      {
        description: "полный срок службы",
        value: "8 лет",
      },
    ]
  },
  {
    title: "Каналы воспроизведения частоты (периода)",
    features: [
      {
        description: "количество каналов воспроизведения частоты (периода)",
        value: "2",
      },
      {
        description: "диапазон воспроизведения частоты",
        value: "от 2 до 50000 Гц",
      },
      {
        description: "относительнаяя погрешность воспроизведения частоты (периода)",
        value: "±1х10-6",
      },
      {
        description: "активный уровень выходного сигнала",
        value: "\"0\" или \"1\"",
        features: [
          {
            description: "логигический \"0\"",
            value: "от 0 до 0.5 В",
          },
          {
            description: "логигический \"1\"",
            value: "от 1 до 24 В",
          },
        ]
      },
    ]
  },
  {
    title: "Каналы воспроизведения пачки импульсов",
    features: [
      {
        description: "количество каналов воспроизведения пачки импульсов",
        value: "2",
      },
      {
        description: "диапазон воспроизведения частоты",
        value: "от 2 до 50000 Гц",
      },
      {
        description: "относительнаяя погрешность воспроизведения частоты (периода)",
        value: "±1х10-6",
      },
      {
        description: "количество импульсов в пачке",
        value: "от 1 до 50 000 000 имп",
      },
      {
        description: "абсолютная погрешность воспроизведения пачки импульсов",
        value: "±1 имп",
      },
      {
        description: "активный уровень выходного сигнала",
        value: "\"0\" или \"1\"",
        features: [
          {
            description: "логигический \"0\"",
            value: "от 0 до 0.5 В",
          },
          {
            description: "логигический \"1\"",
            value: "от 1 до 24 В",
          },
        ]
      },
      {
        description: "активный уровень выходного сигнала детекторов",
        value: "\"0\" или \"1\"",
        features: [
          {
            description: "логигический \"0\"",
            value: "от 0 до 0.5 В",
          },
          {
            description: "логигический \"1\"",
            value: "от 1 до 24 В",
          },
        ]
      },
    ]
  },
  {
    title: "Каналы воспроизведения интервала времени",
    features: [
      {
        description: "количество каналов воспроизведения интервала времени",
        value: "2",
      },
      {
        description: "диапазон воспроизведения интервала времени",
        value: "от 1 до 600 000 мс",
      },
      {
        description: "относительнаяя погрешность воспроизведения частоты (периода)",
        value: "±1 мс",
      },
      {
        description: "активный уровень выходного сигнала детекторов",
        value: "\"0\" или \"1\"",
        features: [
          {
            description: "логигический \"0\"",
            value: "от 0 до 0.5 В",
          },
          {
            description: "логигический \"1\"",
            value: "от 1 до 24 В",
          },
        ]
      },
    ]
  },
  {
    title: "Каналы воспроизведения силы постоянного тока",
    features: [
      {
        description: "количество каналов воспроизведения силы постоянного тока",
        value: "4",
      },
      {
        description: "диапазон воспроизведения силы постоянного тока",
        value: "от 0 до 20 мА",
      },
      {
        description: "абсолютная погрешность воспроизведения силы постоянного тока",
        value: "±3 мкА",
      }
    ]
  },
  {
    title: "Каналы измерения частоты (периода), счёта импульсов и интервала времени",
    features: [
      {
        description: "количество каналов измерения частоты (периода) и счёта импульсов",
        value: "2",
      },
      {
        description: "диапазон измерения частоты (периода)",
        value: "от 2 до 50000 Гц",
      },
      {
        description: "относительнаяя погрешность измерения частоты (периода)",
        value: "±1х10-6",
      },
      {
        description: "количество подсчитываемых импульсов",
        value: "от 2 до 50 000 000 имп",
      },
      {
        description: "абсолютная погрешность счёта импульсов",
        value: "±1 имп",
      },
      {
        description: "активный уровень входного сигнала",
        value: "\"0\" или \"1\"",
      },
      {
        description: "количество каналов измерения интервала времени",
        value: "2",
      },
      {
        description: "диапазон измеряения интервала времени",
        value: "от 1 до 600 000 мс",
      },
      {
        description: "абсолютная погрешность измерения интервала времени",
        value: "±1 мс",
      },
      {
        description: "активный уровень входного сигнала детекторов",
        value: "\"0\" или \"1\"",
      },
    ]
  },
  {
    title: "Каналы измерения силы постоянного тока",
    features: [
      {
        description: "количество каналов измерения силы постоянного тока",
        value: "1",
      },
      {
        description: "диапазон измерения силы постоянного тока",
        value: "от 0 до 20 мА",
      },
      {
        description: "абсолютная погрешность измерения силы постоянного тока",
        value: "±10 мкА",
      }
    ]
  },
  {
    title: "Каналы измерения напряжения",
    features: [
      {
        description: "количество каналов измерения напряжения",
        value: "1",
      },
      {
        description: "диапазон измерения напряжения",
        value: "от 0 до 30 В",
      },
      {
        description: "абсолютная погрешность измерения напряжения",
        value: "±10 мВ",
      }
    ]
  },
]

const Features = ({opened, setOpened}) => {
  return (
    <S.Container>
      <FootNote>* - на 3D-модели прибора отображается внешний вид экрана, соответсвующий выбранному каналу</FootNote>
      <S.Name>
        <S.Model>УПВА</S.Model>
        <S.Separator>-</S.Separator>
        <S.SeriesWrapper>
          <S.Series>М2</S.Series>
        </S.SeriesWrapper>
      </S.Name>
      <S.Links>
        <S.Link target={"_blank"} href={"https://fgis.gost.ru/fundmetrology/registry/4/items/1387478"}>Запись об утверждении типа СИ в ФИФ по ОЕИ</S.Link>
        <S.Link target={"_blank"} href={"https://pub.fsa.gov.ru/rds/declaration/view/14528539/declaration"}>Запись в едином реестре о соответствии требованиям ТР ТС 004/2011 и ТР ТС 020/2011</S.Link>
      </S.Links>
      <S.Groups>
        {
          groups.map((group, index) => (
            <FeatureGroup key={index} {...group} opened={index + 1 === opened} open={() => setOpened(index + 1)} close={() => {setOpened(0)}}/>
          ))
        }
      </S.Groups>
    </S.Container>
  )
}

export default Features