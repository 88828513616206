import styled from "styled-components";

export const Container = styled.tr`
  display: flex;
  flex-direction: row;
  color: white;
  padding: 2px 0;
  font-size: max(1.2vh, 14px);
`;

export const Description = styled.td.attrs(props => ({
  style: {
    paddingLeft: props.subFeature ? "24px" : "0",
  }
}))`
  flex: 4;
`

export const Value = styled.td`
  text-align: right;
  flex: 3;
`;