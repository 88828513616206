import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  @media screen and (min-device-aspect-ratio: 1 / 1) {
    height: 70vh;
  }
  height: 50vh;
  background: white;
`;