import * as S from './styles'
import Feature from "../Feature";

const FeatureGroup = ({ title, features, opened, open, close }) => {
  return <S.Container>
    <S.Title opened={opened} onClick={() => opened ? close() : open()}>
      <S.TitleText><S.Indicator>{opened ? "-" : "+"}</S.Indicator>{title}</S.TitleText>
    </S.Title>
    <S.Features opened={opened}>
      <S.FeaturesBody>
      {
        features.map((feature, index) => (
          <Feature key={index} {...feature}/>
        ))
      }
      </S.FeaturesBody>
    </S.Features>
  </S.Container>
}

export default FeatureGroup