import GlobalStyle from "./styles";
import Index from "./pages/Index";

function App() {
  return (
    <>
      <Index/>
      <GlobalStyle/>
    </>
  );
}

export default App;
