import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40vw;
  top: 0;
  min-height: 10vh;
  width: 100%;
  background-color: rgb(16, 16, 16);
  z-index: 3;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4vw;
  height: 100%;
  width: 50%;
  min-width: min(900px, 90vw);
  padding: 2vh;
`

export const Logo = styled.div`
  position: relative;
  height: 100%;
`

export const Image = styled.img`
  height: 10vh;
  padding: 1vh 0;
`

export const Text = styled.div`
  position: absolute;
  bottom: 1.5vh;
  color: white;
  font-size: 1.5vh;
  font-weight: 800;
`

export const Phrases = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1vh;
`

export const Phrase = styled.p`
  width: 100%;
  font-size: max(1.2vh, 14px);
  color: white;
  margin: 0;
`