import {Suspense} from "react";
import * as S from './styles'
import {Canvas} from "@react-three/fiber";
import {Gltf, OrbitControls} from "@react-three/drei";

import model1 from '../../models/calibrator_1.glb';
import model2 from '../../models/calibrator_2.glb';
import model3 from '../../models/calibrator_3.glb';
import model4 from '../../models/calibrator_4.glb';
import model5 from '../../models/calibrator_7.glb';
import model6 from '../../models/calibrator_6.glb';
import model7 from '../../models/calibrator_5.glb';
import model8 from '../../models/calibrator_8.glb';
import {ColorRing} from "react-loader-spinner";

const models = [model4, model4, model1, model3, model8, model7, model2, model5, model6];

const Inspector = ({opened}) => {
  return <S.Container>
    <Suspense fallback={
      <ColorRing
        visible={true}
        height="80"
        width="80"
        colors={['violet', 'blue', 'green', 'orange', 'red']}
      />
    }
    >
      <Canvas camera={{ fov: 50, position: [0, 2, 6] }}>
        <Gltf src={models[opened]} receiveShadow castShadow />
        <ambientLight intensity={1.1} />
        <OrbitControls minDistance={2.3} maxDistance={5} enablePan={false}/>
      </Canvas>
    </Suspense>
  </S.Container>
}

export default Inspector