import * as S from './styles'

const Feature = ({ description, value, features, subFeature }) => {
  return (
    <>
      <S.Container>
        <S.Description subFeature={subFeature}>•{description}</S.Description>
        <S.Value>{value}</S.Value>
      </S.Container>
      {
        features?.map((feature, index) => (
          <Feature key={index} {...feature} subFeature />
        ))
      }
    </>
  )
}

export default Feature