import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.div.attrs(props => ({
  style: {
    color: props.opened ? "yellow" : "cornflowerblue"
  }
}))`
  display: flex;
  gap: 0.2vw;
  flex-direction: row;
  align-items: center;  
  cursor: pointer;
  user-select: none;
  font-size: 2.5vh;
  font-weight: 800;
`

export const Indicator = styled.span`
  display: inline-block;
  text-align: left;
  height: 2.5vh;
  aspect-ratio: 1 / 1;
`

export const TitleText = styled.div`
  margin: 32px 0 16px 0;
`

export const Features = styled.table.attrs(props => ({
  style: {
    display: props.opened ? "flex" : "none"
  }
}))`
  width: 100%;
`;
export const FeaturesBody = styled.tbody`
  width: 100%;
`;