import logo from '../../images/logo.svg'
import * as S from './styles'

const Header = () => {
  return <S.Container>
    <S.Header>
      <S.Logo>
        <S.Image src={logo}/>
        <S.Text>ПТП Прибор</S.Text>
      </S.Logo>
      <S.Phrases>
        <S.Phrase>Больше 20 лет производим средства поверки</S.Phrase>
        <S.Phrase>Выполняем ремонт и техническое обслуживание калибраторов КИК&#8209;М, КЭС, КЭС&#8209;М, УПВА&#8209;М1 и УПВА&#8209;М2</S.Phrase>
      </S.Phrases>
    </S.Header>
  </S.Container>
}

export default Header