import * as S from './styles'

const Footer = () => {
  return (
    <S.Container>
      <S.Footer>
        <S.Company>ООО "ПТП Прибор"</S.Company>
        <S.Phone>+7-381-232-76-62.</S.Phone>
        <S.Phone>8-913-988-24-25</S.Phone>
        <S.Email>ptp-pribor@yandex.ru</S.Email>
        <S.Site>птп-прибор.рф</S.Site>
      </S.Footer>
    </S.Container>
  )
}

export default Footer