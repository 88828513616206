import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(16, 16, 16);
  padding: 0 0 96px 0;
  z-index: 2;
`;

export const FootNote = styled.p`
  color: white;
  font-size: max(1.1vh, 12px);
  text-align: center;
`

export const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  height: 10vh;
  gap: 8px;
`

export const Model = styled.h1`
  font-size: 6vh;
  font-weight: 900;
  margin: 0;
`

export const Separator = styled.h1`
  font-size: 6vh;
  font-weight: 900;
`

export const SeriesWrapper = styled.div`
  background: conic-gradient(
          from 90deg,
          violet,
          indigo,
          blue,
          green,
          yellow,
          orange,
          red,
          violet
  );
  padding: 0.5vh;
  height: min-content;
  border-radius: 1.4vh;
`

export const Series = styled.h1`
  font-size: 5vh;
  font-weight: 900;
  border-radius: 1.2vh;
  padding: 0.7vh 2.1vh;
  background: rgb(16, 16, 16);
  margin: 0;
`

export const Groups = styled.div`
  width: 50%;
  min-width: min(900px, 90vw);
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 50%;
  min-width: min(900px, 90vw);
  padding-top: 4vh;
`

export const Link = styled.a`
  font-weight: 800;
  color: gray;
  :hover {
    color: white;
  }
`