import * as S from './styles'
import Features from "../../components/Features";
import Inspector from "../../components/Inspector";
import {useState} from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Index = () => {
  const [opened, setOpened] = useState(0);
  return <S.Container>
    <Header/>
    <Inspector opened={opened}/>
    <Features opened={opened} setOpened={setOpened}/>
    <Footer/>
  </S.Container>
}

export default Index