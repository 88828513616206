import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  color: white;
  background-color: black;
  z-index: 1;
  width: 100%;
`;

export const Footer = styled.div`
  width: 50%;
  min-width: min(900px, 90vw);
`

export const Company = styled.div`
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 8px;
`

export const Phone = styled.div`
  font-size: max(1.2vh, 14px);
  margin-bottom: 2px;
`

export const Email = styled.div`
  font-size: max(1.2vh, 14px);
  margin-bottom: 2px;

`

export const Site = styled.div`
  font-size: max(1.2vh, 14px);
  margin-bottom: 2px;
`